<template>
  <div class="process-wrapper">
    <div>
      <div v-if="!claimed">
        <TaskBlocker :assignee="data.assignee" />
      </div>
      <div v-if="!isLoading">
        <v-alert
          v-if="!isCamundaAdmin && processedUserId === username"
          text
          type="warning"
        >
          Die Aufgabe kann nicht von der selben Person erstellt und bearbeitet werden.
        </v-alert>
        <v-alert
          v-if="!tableLoading && totalDataTableCount && totalDataTableCount !== processInstanceIdList.length"
          dense
          prominent
          type="warning"
        >
          Es wurden {{ processInstanceIdList.length }} Verträge für die {{ isHomePassedAcceptRequest ? 'Freigabe' : 'Ablehnung' }} angefragt,
          davon befinden sich derzeit {{ totalDataTableCount }} in der Home-Passed Freigabe!
        </v-alert>
        <display-container class="my-4">
          <div class="details">
            <data-display :label="`HomePassed ${isHomePassedAcceptRequest ? 'Freigabe' : 'Ablehnung'} von ${totalDataTableCount} Verträgen`">
              <i>{{ processedUserId }}</i> hat <i>{{ totalDataTableCount }}</i> Verträge zur {{ isHomePassedAcceptRequest ? 'Freigabe' : 'Ablehnung' }} angefragt.
            </data-display>
          </div>
        </display-container>

        <v-data-table
          :headers="reportHeaders"
          :items="homePassedTable"
          checkbox-color="primary"
          item-key="processInstanceId"
          class="elevation-1"
          :loading="tableLoading"
          loading-text="Daten werden geladen..."
          no-data-text="Es sind keine Einträge vorhanden."
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            disableItemsPerPage: tableLoading,
            disablePagination: tableLoading,
            'items-per-page-text': 'Zeilen pro Seite:',
            'items-per-page-options': [10, 20, 60, 120, 500]
          }"
          :items-per-page="60"
          group-by="project"
          :server-items-length="totalDataTableCount"
          :options.sync="options"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>
                Home-Passed Prozesse in {{ isHomePassedAcceptRequest ? 'Freigabe' : 'Ablehnung' }}
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
            <td :colspan="reportHeaders.length">
              <v-icon @click="toggle">
                {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
              </v-icon>
              <span>Projekt: {{ items[0].project }}, Projektphase:
                {{ items[0].projectDescription }}
              </span>
            </td>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn-toggle
              v-model="item.action"
              color="primary"
              mandatory
              tile
              group
              dense
            >
              <v-btn value="accept" title="Akzeptieren">
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn value="none">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-btn value="reject" title="Ablehnen">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-btn-toggle
              color="primary"
              class="ml-2"
              :value.sync="allToggleSelected"
              tile
              group
              dense
            >
              <v-btn
                :disabled="allToggleSelected === ACTION.ACCEPT"
                :value="ACTION.ACCEPT"
                title="Alle akzeptieren"
                @click.prevent="toggleAll(ACTION.ACCEPT)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn
                :disabled="allToggleSelected === ACTION.NONE"
                :value="ACTION.NONE"
                @click.prevent="toggleAll(ACTION.NONE)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-btn
                :disabled="allToggleSelected === ACTION.REJECT"
                :value="ACTION.REJECT"
                title="Alle ablehnen"
                @click.prevent="toggleAll(ACTION.REJECT)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-data-table>

        <div class="buttons-wrapper mt-4">
          <v-dialog v-model="submitDialog" max-width="520">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :disabled="isActionDisabled"
                color="primary"
                v-on="on"
              >
                <v-icon small>
                  mdi-check-circle
                </v-icon>
                Absenden
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5 blue lighten-2 mb-4">
                Änderungen an {{ selectedProcessesAmount }} Prozess{{ (selectedProcessesAmount > 1 ? 'en' : '') }} speichern?
              </v-card-title>
              <v-card-text class="pb-0 body-1">
                <template v-if="selectedAcceptProcesses.length > 0">
                  <span><i>{{ selectedAcceptProcesses.length }}</i> {{ isHomePassedAcceptRequest ? 'Freigaben' : 'Ablehnungen' }} werden dadurch <strong>akzeptiert</strong></span><br />
                  <span v-if="isHomePassedAcceptRequest">Dadurch werden die jeweiligen Prozesse weiter prozessiert, und erhalten entsprechend auch Kundenkommunikation!</span>
                  <span v-if="isHomePassedRejectRequest">Dadurch werden die jeweiligen Prozesse beendet, und werden nicht weiter prozessiert! Die Kunden erhalten zusätzlich eine Information über die Ablehnung.</span>
                  <template v-if="selectedRejectProcesses.length > 0">
                    <br /><br /><hr /><br />
                  </template>
                </template>
                <template v-if="selectedRejectProcesses.length > 0">
                  <span><i>{{ selectedRejectProcesses.length }}</i> {{ isHomePassedAcceptRequest ? 'Freigaben' : 'Ablehnungen' }} werden dadurch <strong>abgelehnt</strong></span><br />
                  <span>Dadurch werden die Prozesse wieder in der <code>Home-Passed Freigabe</code> angezeigt!</span>
                </template>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  text
                  :disabled="submitLoading"
                  @click="submitDialog = false"
                >
                  Nein
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  :loading="submitLoading"
                  @click="completeTask()"
                >
                  Ja
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import { objectToQueryParamString } from '@/util/ObjectUtil.js';
import NotificationObject from '@/main/NotificationObject.js';

const ACTION = {
  ACCEPT: 'accept',
  NONE: 'none',
  REJECT: 'reject'
}

export default {
  name: 'HomePassedUserTask',
  components: {
    TaskBlocker: () => import('../TaskBlocker.vue'),
    DataDisplay: () => import('@/components/elements/DataDisplay.vue'),
    DisplayContainer: () =>
      import('@/components/elements/DisplayContainer.vue'),
    Loader: () => import('@/components/Loader.vue'),
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      tableLoading: false,
      submitLoading: false,
      processInstanceIdList: [],
      processedAction: null,
      processedUserId: null,
      homePassedTable: [],
      options: {},
      totalDataTableCount: 0,
      submitDialog: false,
      allToggleSelected: ACTION.NONE,
      ACTION: {
        ACCEPT: ACTION.ACCEPT,
        NONE: ACTION.NONE,
        REJECT: ACTION.REJECT
      },
      reportHeaders: [
        {
          text: 'Aktion',
          value: 'action',
          sortable: false,
          width: '13rem',
          align: 'start'
        },
        {
          text: 'Adresse',
          value: 'address',
          sortable: false
        },
        {
          text: 'Vertrag',
          align: 'start',
          value: 'contractId',
          sortable: false
        },
        {
          text: 'Projekt',
          align: 'start',
          value: 'project',
          sortable: false
        },
        {
          text: 'Auftragsdatum',
          value: 'contractDate',
          sortable: false
        },
        {
          text: 'Home Passed Grund',
          value: 'homePassedReason',
          sortable: false
        }
      ],
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    isCamundaAdmin() {
      return this.$auth.user().userRoles.includes('camunda-admin');
    },
    isActionDisabled() {
      return this.isLoading || !this.claimed || (!this.isCamundaAdmin && this.username === this.processedUserId) || this.selectedProcessesAmount === 0;
    },
    isHomePassedAcceptRequest() {
      return this.processedAction === ACTION.ACCEPT;
    },
    isHomePassedRejectRequest() {
      return this.processedAction === ACTION.REJECT;
    },
    selectedAcceptProcesses() {
      return this.homePassedTable.filter(e => e.action === ACTION.ACCEPT);
    },
    selectedRejectProcesses() {
      return this.homePassedTable.filter(e => e.action === ACTION.REJECT);
    },
    selectedProcessesAmount() {
      return this.selectedAcceptProcesses.length + this.selectedRejectProcesses.length;
    }
  },
  watch: {
    homePassedTable: {
      handler(newVal) {
        const ret = newVal.reduce((p, c) => {
          if (!p.includes(c.action)) p.push(c.action);
          return p;
        }, []);
        if (ret.length === 1) {
          this.allToggleSelected = ret[0];
        } else {
          this.allToggleSelected = undefined;
        }
      },
      deep: true
    },
    options: {
      handler(newVal, oldVal) {
        if (this.tableLoading) return;
        let reloadRequired = false;

        if (newVal.itemsPerPage !== oldVal.itemsPerPage && newVal.itemsPerPage < this.totalDataTableCount) {
          reloadRequired = true;
        }

        if (newVal.page !== oldVal.page) {
          reloadRequired = true;
        }

        if (reloadRequired) {
          this.loadHomePassedEntries();
        }
      },
      deep: true
    },
  },
  mounted: function () {
    this.loadTaskData();
  },
  methods: {
    loadTaskData: function () {
      return new Promise((resolve) => {
        this.isLoading = true;
        this.data.variables.then((data) => {
          this.processInstanceIdList = data.homePassedProcessInstanceIdList.value;
          this.processedAction = data.homePassedProcessedAction.value;
          this.processedUserId = data.homePassedProcessedUserId.value;
        }).finally(() => {
          this.isLoading = false;
          return resolve();
        });
      });
    },
    loadHomePassedEntries: function () {
      this.tableLoading = true;

      const { page, itemsPerPage } = this.options;
      const queryObject = {
        page: page || 1,
        perPage: itemsPerPage || 10,
      };
      const query = objectToQueryParamString(queryObject);

      return HTTP.get(`/orderTask/homePassedProcesses/${this.data.id}?${query}`)
        .then(({ data }) => {
          return { total: data.total, data: this.buildDashboardData(data.processInstances) };
        })
        .then(({ total, data }) => {
          this.homePassedTable = data;
          this.totalDataTableCount = total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    buildDashboardData(response) {
      return response.map((dataRow) => {
        const installationAddress = this.getAddress(dataRow.customerDto);
        const date = new Date(Date.parse(dataRow.processInfo.orderDate));
        return {
          action: ACTION.NONE,
          address: installationAddress,
          projectName: dataRow.processInfo.projectName,
          projectDescription: dataRow.processInfo.projectDescription,
          contractDate: date.toLocaleDateString('de-DE'),
          processInstanceId: dataRow.processInstanceId,
          project: `${dataRow.processInfo.projectId} - ${dataRow.processInfo.projectName}`,
          projectId: dataRow.processInfo.projectId,
          contractId: dataRow.processInfo.contractId,
          homePassedReason: dataRow.processInfo.homePassedReason
        };
      });
    },
    getAddress(addressDetails) {
      if (addressDetails === null) {
        return '';
      }
      var houseDetails = addressDetails.houseNumber;
      if (addressDetails.houseNumberSupplement) {
        houseDetails += addressDetails.houseNumberSupplement;
      }
      return (
        addressDetails.street +
        ' ' +
        houseDetails +
        ', ' +
        addressDetails.zipCode +
        ' ' +
        addressDetails.city
      );
    },
    completeTask: function () {
      this.submitLoading = true;
      const postData = {
        acceptedProcessInstanceIdList: this.selectedAcceptProcesses.map(e => e.processInstanceId),
        rejectedProcessInstanceIdList: this.selectedRejectProcesses.map(e => e.processInstanceId),
      };

      HTTP.post(`/orderTask/homePassedProcesses/completeTask/${this.data.id}`, postData)
        .then(async () => {
          if (this.selectedProcessesAmount === this.totalDataTableCount) {
            this.$store.commit('triggerTasksRefresh');
            this.$emit('onClose');
          } else {
            this.data.variables = this.fetchTaskVariables();
            await this.loadTaskData();
            this.loadHomePassedEntries();
          }
          this.submitDialog = false;
        })
        .catch(() =>
          this.showToast('Beim speichern der Daten ist ein Fehler aufgetreten!')
        )
        .finally(() => {
          this.submitLoading = false;
        });
    },
    showToast(message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    },
    toggleAll(newVal) {
      if (this.allToggleSelected === newVal) return;
      this.allToggleSelected = newVal;
      this.toggleAllAction(newVal);
    },
    toggleAllAction(newVal) {
      if (![ACTION.NONE, ACTION.ACCEPT, ACTION.REJECT].includes(newVal)) return;
      this.homePassedTable.forEach(p => {
        p.action = newVal;
      });
    },
    fetchTaskVariables: function () {
      return new Promise((resolve, reject) => {
        HTTP.get(`/task/${this.data.id}/variables`)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((err) => {
            this.showError('Die Prozessvariablen konnten nicht geladen werden!');
            return reject(err);
          });
      });
    }
  }
};
</script>
<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}
</style>
